import React, { type FunctionComponent } from 'react';
import fergusonBklLogo from './ferguson-bkl-logo.svg';
import fergusonStudioLogo from './ferguson-studio-logo.svg';

type FergusonBklLogoWithViewBoxSvgProps = {
	className?: string;
};

/**
 * Output the Ferguson BKL Logo, with an added viewBox attribute that gives the SVG an aspect ratio to enable
 * proper scaling. This allows setting only the width or height, and letting the other dimension scale while keeping
 * the same aspect ratio; this makes it behave similar to <img> elements.
 */
export const FergusonBklLogoWithViewBoxSvg: FunctionComponent<FergusonBklLogoWithViewBoxSvgProps> = ({ className }) => (
	<svg viewBox="0 0 1042 231" className={`${className || 'w-100'}`} data-testid="FergusonBklLogo">
		<use xlinkHref={fergusonBklLogo} />
	</svg>
);

/**
 * Output the Ferguson Studio Logo, with an added viewBox attribute that gives the SVG an aspect ratio to enable
 * proper scaling. This allows setting only the width or height, and letting the other dimension scale while keeping
 * the same aspect ratio; this makes it behave similar to <img> elements.
 */
export const FergusonStudioLogoWithViewBoxSvg: FunctionComponent<FergusonBklLogoWithViewBoxSvgProps> = ({ className }) => (
	<svg viewBox="0 0 650 231" className={`${className || 'w-100'}`} data-testid="FergusonStudioLogo">
		<use xlinkHref={fergusonStudioLogo} />
	</svg>
);
