import { useCookies } from '@fergdigitalcommerce/fergy-react-components';
import React, { useEffect, useState, type FunctionComponent, type PropsWithChildren } from 'react';
import { TEN_YEAR_EXPIRATION } from '../../constants/cookies';
import { FEATURE_FLAGS } from '../../constants/general';
import { useFeature } from '../../hooks/features/features.hooks';
import { AlertNotification } from '../common-components/alert-notification/alert-notification.component';

const COOKIE_NAME = 'omni-alert-notification';

export type OmniAlertNotificationProps = PropsWithChildren<{
	// this should be unique per usage, used in cookie to save closed state
	id: string;
	wrapperStyle?: string;
}>;

export const OmniAlertNotification: FunctionComponent<OmniAlertNotificationProps> = ({ id, wrapperStyle = 'mv2', children }) => {
	const featEnabled = useFeature(FEATURE_FLAGS.OMNI_ANNOUNCEMENT_BANNER);
	const { getCookie, setCookie } = useCookies();
	const closedAlerts = getCookie(COOKIE_NAME)?.split(',') || [];
	const alertEnabled = featEnabled && !closedAlerts.includes(id);
	const [showAlert, setShowAlert] = useState(alertEnabled);

	useEffect(() => {
		setShowAlert(alertEnabled);
	}, [alertEnabled]);

	const closeAlert = () => {
		closedAlerts.push(id);
		setCookie(COOKIE_NAME, closedAlerts.join(','), TEN_YEAR_EXPIRATION);
		setShowAlert(false);
	};

	return showAlert ? (
		<div className={wrapperStyle}>
			<AlertNotification alertType="info" showCloseButton={true} size="MEDIUM" onClose={closeAlert}>
				{children}
			</AlertNotification>
		</div>
	) : null;
};
