import { type AnalyticsEvent } from './analytics.helper';
import { type TrackedEvent } from './event-types';
import { type GTMCustomEvent, type GTMEventData } from './gtm/gtm-types';

export function generateAnalyticsEvent(event: TrackedEvent): AnalyticsEvent {
	return { data: generateCustomGTMEvent(event) };
}

export function generateCustomGTMEvent(event: TrackedEvent, additionalData: GTMEventData = {}): GTMCustomEvent {
	return { ...additionalData, event };
}
