import { SHOP_BY_LOOK_LINK } from '../links';

export const BASE_ID = 'mediabase/site/build.com/images/projects/styles/';

export const BASE_VIEW_ALL_URL = `${SHOP_BY_LOOK_LINK}?style=`;

export const SHOP_BY_LOOK_CAROUSEL_IMAGE_DATA = [
	{
		name: 'Style',
		value: 'Traditional',
		viewAllUrlParam: 'Traditional',
		imageId: 'Traditional'
	},
	{
		name: 'Style',
		value: 'Organic Modern',
		viewAllUrlParam: 'Organic Modern',
		imageId: 'Organic Modern'
	},
	{
		name: 'Style',
		value: 'Rustic',
		viewAllUrlParam: 'Rustic',
		imageId: 'Rustic'
	},
	{
		name: 'Style',
		value: 'Farmhouse',
		viewAllUrlParam: 'Farmhouse',
		imageId: 'Farmhouse'
	},
	{
		name: 'Style',
		value: 'Mid-Century Modern',
		viewAllUrlParam: 'Mid-Century Modern',
		imageId: 'Mid-Century Modern'
	},
	{
		name: 'Style',
		value: 'Modern',
		viewAllUrlParam: 'Modern',
		imageId: 'Modern'
	},
	{
		name: 'Style',
		value: 'Industrial',
		imageId: 'Industrial'
	},
	{
		name: 'Style',
		value: 'Transitional',
		imageId: 'Transitional'
	},
	{
		name: 'Style',
		value: 'Coastal',
		imageId: 'Coastal'
	},
	{
		name: 'Style',
		value: 'Luxe',
		imageId: 'Luxe'
	}
];
