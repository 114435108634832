/**
 * Formats a phone number to format (555) 555-5555.
 * Can be used to format while user types
 *
 * {@link https://stackoverflow.com/a/30058928/475087}
 */
export const formatPhoneNumber = (input?: string): string => {
	if (!input) {
		return '';
	}
	// Strip all characters from the input except digits
	input = input.replace(/\D/g, '');

	// Trim the remaining input to ten characters, to preserve phone number format
	input = input.substring(0, 10);

	// Based upon the length of the string, we add formatting as necessary
	const size = input.length;
	if (size === 0) {
		return input;
	}

	if (size < 4) {
		input = `(${input}`;
	} else if (size < 7) {
		input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}`;
	} else {
		input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}-${input.substring(6, 10)}`;
	}

	return input;
};
