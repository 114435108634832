import React, { type FunctionComponent } from 'react';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';
import { type BaseButtonProps } from '../base-button/base-button.component';
import { type ButtonStyle } from '../button.types';
import { StyledButton } from '../styled-button/styled-button.component';

export type LinkButtonProps = {
	url: string;
	buttonStyle?: Extract<ButtonStyle, 'PRIMARY' | 'SECONDARY' | 'INTERNAL'>;
	size?: 'SMALL' | 'DEFAULT' | 'LARGE';
} & Omit<BaseButtonProps, 'className'>;

/**
 * A StyledButton that supports navigating to a URL
 */
export const LinkButton: FunctionComponent<LinkButtonProps> = ({ url, buttonStyle = 'PRIMARY', ...remainingProps }) => {
	const navigate = useNavigation();
	const onClick = () => {
		navigate(url);
	};

	return <StyledButton buttonStyle={buttonStyle} onClick={onClick} {...remainingProps} />;
};
