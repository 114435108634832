import React, { type FunctionComponent, type PropsWithChildren } from 'react';

export type GreyBoxProps = {
	className?: string;
};

export const GreyBox: FunctionComponent<PropsWithChildren<GreyBoxProps>> = ({ children, className = '' }) => (
	<div className={`bg-theme-grey-lighter pa3 w-100 center ${className}`} data-testid="greyBox">
		{children}
	</div>
);
