import React, { type FunctionComponent, type MouseEvent } from 'react';
import { generateAnalyticsEvent } from '../../../helpers/analytics/analytics-event.util';
import { TrackedEvent } from '../../../helpers/analytics/event-types';
import { StyledButton } from '../../buttons';
import { PanelComponent } from '../../common-components/panel-component/panel-component.component';

export type CreateAccountStartProps = {
	onCreateAccount?: (e: MouseEvent<HTMLElement>) => void;
};

const CREATE_ACCOUNT_START_EVENT = generateAnalyticsEvent(TrackedEvent.CREATE_ACCOUNT_START);

export const CreateAccountStart: FunctionComponent<CreateAccountStartProps> = ({ onCreateAccount }) => (
	<PanelComponent headingContent="Create an Account" className="h-100 f4 br2" headerClassName="pa2" containerClassName="pa1">
		<div className="ml3">
			<ul className="f5 pl3 pb4">
				<li>Easily track and manage your orders</li>
				<li className="mt2">Save all of your order history and set up returns</li>
				<li className="mt2">Quickly checkout</li>
				<li className="mt2">Save, organize, and share your favorite products with the Project tool</li>
				<li className="mt2">Review the products you purchased</li>
			</ul>
			<StyledButton onClick={onCreateAccount} analyticsEvent={CREATE_ACCOUNT_START_EVENT}>
				Create Account
			</StyledButton>
		</div>
	</PanelComponent>
);
