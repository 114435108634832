export const SHORT_TO_FULL_DAY_NAME = {
	mon: 'monday',
	tue: 'tuesday',
	wed: 'wednesday',
	thu: 'thursday',
	fri: 'friday',
	sat: 'saturday',
	sun: 'sunday'
};

export const FALLS_CHURCH_STUDIO_BRANCH_PAGE_URL = 'falls-church-va-showroom-1133';
